.gradient-border {
  position: relative;
}

.gradient-border::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(to right, #8f00ff, #ffa500);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.circular-gradient-border {
  position: relative;
  border: none;
  border-radius: 36px;
  isolation: isolate;
}
.circular-gradient-border::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    60deg,
    #8f00ff,
    #ffa500,
    #8f00ff,
    #ffa500,
    #8f00ff,
    #ffa500,
    #8f00ff,
    #ffa500
  );
  border-radius: 36px;
  z-index: 1;
  animation: animatedgradient 2s linear infinite;
  will-change: background-position;
  background-size: 400% 400%;
  animation-play-state: running;
}
.circular-border {
  border-radius: 36px;
  background: linear-gradient(
    60deg,
    #8f00ff,
    #ffa500,
    #8f00ff,
    #ffa500,
    #8f00ff,
    #ffa500,
    #8f00ff,
    #ffa500
  );
  animation: animatedgradient 1s linear infinite;
  background-size: 400% 400%;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
    /* opacity: 0; */
  }
  50% {
    background-position: 100% 50%;
    /* opacity: 1; */
  }
  100% {
    background-position: 0% 50%;
    /* opacity: 0; */
  }
}

@media (max-width: 768px) {
  .circular-gradient-border {
    border-radius: 31px 31px 0 0; /* Remove border radius on bottom corners */
  }
}
